<template>
  <div class="">111
    <el-button @click="jump">跳转认证详情</el-button>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {},
  methods: {
    jump(){
      this.$router.push(
        {
          path:'/xhomeInfo',
          query:{
            course_id:9776
          }
        }
      )
    }
  }
}
</script>

<style scoped lang=""></style>
